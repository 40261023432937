import styles from "/components/experiments/reskin/organisms//Page.module.scss";
import Page from "/components/experiments/reskin/organisms//Page";
import Panel from "/components/experiments/reskin/organisms//Panel";
import PanelHeading from "/components/experiments/reskin/molecules/PanelHeading";
import PanelSpacer from "/components/experiments/reskin/molecules/PanelSpacer";
import LeadTypeButton from "/components/experiments/reskin/molecules/LeadTypeButton";
import { isMobile as isMobileDetect } from "react-device-detect";

import { useTranslation } from "react-i18next";
import ViewAgentsUserPrompt from "../../navigation/ViewAgentsUserPrompt";
import { useRouter } from "next/router";
import { createFunnelRequest } from "../../../config/Analytics";
import { ASSET_BASE_URL } from "../../../config/constants";
import { useExperiment } from "../../../contexts/ExperimentContext";
import SellerIcon from "../../svg/SellerIcon";
import BuyerIcon from "../../svg/BuyerIcon";
import BuyerSellerIcon from "../../svg/BuyerSellerIcon";
import { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";

export default function Reskin({ agentTaxonomy, chosenLocale, setLeadType }) {
  const { t } = useTranslation(["translation", "common"]);
  const router = useRouter();

  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    // Handler function to update the state with the new window width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { experiment } = useExperiment();

  const leadButtonClick = async (leadType) => {
    const parameters = { leadType };
    await createFunnelRequest({
      page: ASSET_BASE_URL + router.asPath.split("?")[0],
      request_type: "POST",
      parameters,
    });

    setLeadType(leadType);
  };

  const iconSize = windowWidth < 1024 ? 36 : 50;

  return (
    <>
      {<ViewAgentsUserPrompt type={router.query.leadType} />}
      <link
        rel="canonical"
        href={"https://www.realestateagents.com/compare-agents"}
      />
      <Page>
        <div className={css(aphStyles.mobile)}>
          <h1 className={[styles["Page__heading"]].join(" ")}>
            <div>
              {t(chosenLocale ? "headline_alternate" : "headline_experiment", {
                chosenLocale,
                agent_taxonomy: agentTaxonomy,
              })}
            </div>{" "}
            <br />
            {t("in")} {chosenLocale ? chosenLocale : t("your_area")}
          </h1>
          <div
            className={[
              styles["Page__sub-heading"],
              css(aphStyles.desktop),
            ].join(" ")}
          >
            {t("signup02.rea.subheading")}
          </div>
          <div
            className={[
              styles["Page__sub-heading"],
              css(aphStyles.mobile),
            ].join(" ")}
          >
            {t("signup02.rea.subheading_mobile")}
          </div>
        </div>
        <Panel
          className={[
            styles["Page__panel"],
            styles["Page__panel_new_landing"],
          ].join(" ")}
          showProgressBar={!isMobile}
          large={false}
        >
          <div className={css(aphStyles.desktop)}>
            <h1
              className={[
                styles["Page__heading"],
                styles["Page__heading_new"],
              ].join(" ")}
            >
              <div>
                {t(`headline_alternate${windowWidth < 1024 ? "_mobile" : ""}`, {
                  chosenLocale,
                  agent_taxonomy: agentTaxonomy,
                })}
              </div>{" "}
              {chosenLocale ? <br /> : null}
              {chosenLocale ? t("in") + " " + chosenLocale : t("for_you")}
            </h1>
            <div
              className={[
                styles["Page__sub-heading"],
                styles["Page__sub-heading-new"],
                css(aphStyles.desktop),
              ].join(" ")}
            >
              {t("signup02.rea.subheading")}
            </div>
          </div>

          <div className={css(aphStyles.mobile)}>
            <PanelHeading center={true}>{t("signup02.rea.cta")}</PanelHeading>
          </div>
          <div
            className={[
              styles["Page__button-grid"],
              styles["Page__button-grid-bigger"],
            ].join(" ")}
          >
            <LeadTypeButton
              className={styles["LeadTypeButtonNew"]}
              innerClassName={styles["LeadTypeButtonNewInner"]}
              onClick={() => leadButtonClick("buyer")}
            >
              <BuyerIcon width={iconSize} height={iconSize} />
              {t("signup02.rea.im_buying")}
            </LeadTypeButton>
            <LeadTypeButton
              className={styles["LeadTypeButtonNew"]}
              innerClassName={styles["LeadTypeButtonNewInner"]}
              onClick={() => leadButtonClick("seller")}
            >
              <SellerIcon width={iconSize} height={iconSize} />
              {t("signup02.rea.im_selling")}
            </LeadTypeButton>
            <LeadTypeButton
              className={styles["LeadTypeButtonNew"]}
              innerClassName={styles["LeadTypeButtonNewInner"]}
              onClick={() => leadButtonClick("both")}
            >
              <BuyerSellerIcon width={iconSize} height={iconSize} />
              {t("signup02.rea.im_buying_and_selling")}
            </LeadTypeButton>
          </div>
          <PanelSpacer />
          <ul
            className={[styles["Page__list"], styles["Page__list_bigger"]].join(
              " "
            )}
          >
            <li>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("signup02.rea.benfits.benfit_1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </li>
            <li>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("signup02.rea.benfits.benfit_2", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </li>
            <li>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("signup02.rea.benfits.benfit_3", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </li>
          </ul>
        </Panel>
      </Page>
    </>
  );
}

const aphStyles = StyleSheet.create({
  mobile: {
    "@media only screen and (min-width: 1024px)": {
      display: "none",
    },
  },
  desktop: {
    "@media only screen and (max-width: 1023px)": {
      display: "none",
    },
  },
});
